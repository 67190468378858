import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import { ThemeContext } from '@forma/forma-ui-kit';
import { RequestDemoButton } from '@/components/blocks/RequestDemo';

import styles from './main-banner.module.css';

const MainBanner = ({ title, description, image }) => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  // const [ openVideo, setOpenVideo ] = useState(false);
  const isPhone = viewport === 'phone';

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        {description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
        {!isPhone && (
          <div className={styles.buttons}>
            <RequestDemoButton className={styles.button} icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />} />
            {/* <Button
              as={Link}
              href={`${lkUrl}/register?lng=${i18n.language}`}
              viewStyle="primary"
              className={styles.button}
              icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
            >
              {t('try_free_trial_days')}
            </Button> */}
          </div>
        )}
      </div>
      <div className={styles.right}>
        {/* {isPhone ? (
          <button className={styles.play} onClick={() => setOpenVideo(true)}>
            <ReactSVG src="/icons/play-large.svg" className={styles.playIcon}/>
          </button>
        ) : (
          <ClientWrap>
            <Tooltip
              control={
                <button className={styles.play} onClick={() => setOpenVideo(true)}>
                  <ReactSVG src="/icons/play-large.svg" className={styles.playIcon}/>
                </button>
              }
              offsetX={50}
              offsetY={0}
              position={['bottom left', 'bottom right']}
            >
              {t('watch_video')}
            </Tooltip>
          </ClientWrap>
        )} */}
        <img src={image} className={styles.image} alt="" width="700px" height="520px" />
      </div>
      {isPhone && (
        <div className={styles.buttons}>
          <RequestDemoButton className={styles.button} icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />} />
          {/* <Button
            as={Link}
            viewStyle="primary"
            href={`${lkUrl}/register?lng=${i18n.language}`}
            className={styles.button}
            icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
            onClick={analytics.clickTryTrial}
          >
            {t('try_free_trial_days')}
          </Button> */}
        </div>
      )}

      {/* <ClientWrap>
        <Modal
          open={openVideo}
          onClose={() => setOpenVideo(false)}
          size="large"
        >
          <Video
            className={styles.video}
            src="https://static.forma.today/videos/Forma-Anim_1920-1080_All_5-9_H264.mp4"
          />
        </Modal>
      </ClientWrap> */}
    </div>
  );
};

export default MainBanner;
